.card-header {
    background-color: transparent;
    border-bottom: 2px solid #f5f6f8;
}

.card-footer {
    background-color: transparent;
    padding-top: 0;
}

.card {

    .header-title {
        margin: 0;
    }

    .header-info {
        margin-top: 3px;
        
        .separator {
            margin: 0 8px;
        }
    }

    .form-label {
        margin-bottom: 1px;
    }
}

.card.expand-v {
    height: calc(100% - 24px);
}