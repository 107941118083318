.custom-switch {
    padding-top: 4px;
    padding-bottom: 7px;

    .custom-control-label {
        vertical-align: middle;
        cursor: pointer;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: $dark;
    border-color: $dark;
    background-color: $dark;
}