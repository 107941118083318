.busy-marker {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.data-table + .busy-marker {
    margin-top: 50px;
    padding-top: 7px;
    align-items: flex-start;
}