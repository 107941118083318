@import url('https://fonts.googleapis.com/css?family=Nunito:900&display=swap');

body {
	margin: 0;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #4c555d;
}

@media (min-width: 769px) {

    body.enlarged {
        min-height: 0;
    }
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn.focus,
.btn:focus,
.btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

.btn.disabled,
.btn:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.public-footer {
    color: #6c757d;
}